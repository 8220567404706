@media screen and (min-width: 1220px) {
  .desk {
  }
  .mobile {
    display: none !important;
  }
  a {
    text-decoration: none;
  }

  // 다운로드
  .download-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1ECAD3;
    padding: 100px;
  }

  .download-container .content-box {
    font-family: NotoSansBold;
    font-size: 24px;
    padding: 40px 50px;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
  }

  .content-important {
    color: red;
    font-size: 16px;
  }

  .download-container .btn {
    margin-top: 20px;
    border: 1px solid #000;
    padding: 5px 30px;
    border-radius: 10px;
    cursor: pointer;
  }

  .download-container .content-sub-box {
    font-family: NotoSansBold;
    font-size: 24px;
    padding: 40px 50px;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    margin-top: 30px;
  }

  .download-container .sub-btn {
    margin: 20px 0 69px;
    border: 1px solid #000;
    padding: 5px 30px;
    border-radius: 10px;
    cursor: pointer;
  }

  .download-container .logo-content {
    display: inline-block;
    vertical-align: top;
    padding-left: 50px;
  }

  .download-container .text {
    text-align: center;
    font-size: 35px;
    letter-spacing: -1px;
    font-family: NotoSansBold;
  }
}
