@media screen and (max-width: 1219px) {
  .desk {
    display: none !important;
  }

  .mobile {
  }

  a {
    text-decoration: none;
  }

  // 다운로드
  .download-container {
    width: 100vw;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1ECAD3;

    & .content-box {
      font-family: NotoSansBold;
      font-size: 24px;
      padding: 40px 50px;
      background-color: #fff;
      border-radius: 20px;
      text-align: center;
    }

    & .content-important {
      color: red;
      font-size: 16px;
    }

    & .btn {
      margin-top: 20px;
      border: 1px solid #000;
      padding: 5px 30px;
      border-radius: 10px;
      cursor: pointer;
    }

    & .content-sub-box {
      font-family: NotoSansBold;
      font-size: 24px;
      padding: 40px 50px;
      background-color: #fff;
      border-radius: 20px;
      text-align: center;
      margin: 30px 0 100px 0;
    }

    & .sub-btn {
      margin: 20px 0 69px;
      border: 1px solid #000;
      padding: 5px 30px;
      border-radius: 10px;
      cursor: pointer;
    }

    & .logo-content {
      vertical-align: bottom;
    }

    & .text {
      text-align: center;
      font-size: 35px;
      margin: 15px 0 30px 0;
      letter-spacing: -1px;
      font-family: NotoSansBold;
    }
  }
}
